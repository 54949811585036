.modal-height {
    height: 80vh;
}

.modal-fullscreen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 2rem 3rem 2rem 3rem;
}

.role-form {
    height: 97%;
}

.role-form-table {
    overflow-y: scroll;
    height: 85%;
}

.form-buttons {
    margin-top: 10px;
    margin-inline: 5px;
}

.form-buttons-wrapper {
    text-align: center;
}