/**
    Styling of tooltips
 */
.popper-tooltip {
    z-index: 99;
    background-color: #dc3545;
    color: white;
    border-radius: 4px;
    padding: 2px 4px;
    font-weight: bold;
    font-size: 0.85em;
}

.popper-tooltip-hidden {
    visibility: hidden;
    pointer-events: none;
}

.popper-tooltip-hidden > .arrow {
    visibility: hidden;
}

.popper-tooltip-hidden > .arrow::before {
    visibility: hidden;
}

.arrow,
.arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
}

.arrow {
    visibility: hidden;
}

.arrow::before {
    transform-origin: 0 0;
    visibility: visible;
    content: '';
    transform: rotate(45deg);
}


.popper-tooltip[data-popper-placement^='top'] > .arrow {
    bottom: -4px;
}

.popper-tooltip[data-popper-placement^='bottom'] > .arrow {
    top: -4px;
}

.popper-tooltip[data-popper-placement^='left'] > .arrow {
    right: -4px;
}

.popper-tooltip[data-popper-placement^='right'] > .arrow {
    left: -4px;
}

.logInfo.show {
    opacity: 1;
}

.logInfo > .arrow {
    display: none;
}

.currency_rates.tooltip.show {
    opacity: 1;
}

.currency_rates > .arrow::before {
    transform: rotate(0deg);
}

.currency_rates > .tooltip-inner {
    background-color: #f2f7ff;
    color: #212529;
    font-weight: 400;
    padding: 7px;
    border: 1px solid #ddd;
}

.logInfo > .tooltip-inner {
    background-color: white;
    padding: 2px;
    max-width: 500px;
    max-height: 586px;
    overflow-y: auto;
}
.logSecond > .tooltip-inner{
    max-width: unset;
}

.tooltip-inner > table {
    background-color: white;
    color: #36363a;
    border-collapse: collapse;
    border-radius: 5px;
    border-style: hidden;
    box-shadow: 0 0 0 1px #ddd;
    text-align: left;
    font-family: Roboto, sans-serif;
    font-size: 0.8125rem;
}

.tooltip-inner > table > tbody > tr > td, .tooltip-inner > table > thead > tr > th {
    padding: 8px 15px;
    border: 1px solid #ddd;
    background-color: transparent;
}

.tooltip-inner > table > thead > tr {
    background-color: #e9ecef;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tooltip-inner > table > thead > tr > th {
    color: #36363a;
}

.tooltip-inner > table > tbody > tr:nth-child(even) {
    background-color: #f2f7ff;
}

/**
    END: Styling of tooltips
 */
.loaderHolder.shown {
    display: block;
}

.loaderHolder {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: none;
}

.loaderHolderWrapper {
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: white;
}

.loaderHolder img {
    position: absolute;
    top: calc(100% / 2 - 25px);
    left: calc(100% / 2 - 25px);
    width: 50px;
    z-index: 3;
}

/*.form-control:disabled, .form-control[readonly]{ // uncommented by Pantelis*/
/*    background-color: unset !important;*/
/*}*/
/*.form-control[readonly]{*/
/*    cursor: not-allowed;*/
/*    pointer-events: none;*/
/*}*/

/*select[readonly] {*/
/*    pointer-events: none;*/
/*}*/
input.is-invalid {
    border: 1px solid red;
}

#billForm.notZoomedItems {
    margin-top: 0;
    transition: margin-top ease 1s;
}

#billForm.zoomedItems {
    margin-top: -342px;
    transition: margin-top ease 1s;
}


.MuiSwitch-root {
    margin-top: 23px !important;
}

.label-contract {
    text-align: left;
    margin-left: -85%;
}

.label-damages {
    margin-left: -85%;

}

.horizontal-line {
    width: 28%;
}

.sidebar {
    /*height: 100%;*/
    /*min-width: 300px;*/
    background-color: #f4f9ff;
    border-radius: 8px;
    margin: 0 10px 20px 10px;
    display: flex;
    flex-direction: column;
    width: 350px;
    /*width: 19%;*/
    min-width: 300px;
    max-width: 500px;
    height: calc(100vh - 28px);


}

.sidebarList {
    /*margin-top: 50%;*/
    /*min-height: 500px;*/
    padding: 0;
    width: 100%;
    overflow-y: scroll;
    /*min-height: 300px;*/
    /*max-height: calc(100% - 300px);*/
    /*height: 400px;*/
    height: 85%;

}

.sidebarList .row {
    width: 100%;
    /*height: 40px;*/
    list-style-type: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    color: darkgrey;
    position: relative;
    /*justify-content: center;*/

    align-items: center;
    /*font-family: 'Trebuchet MS', 'Lucida Sans Unicode, 'Lucida Grande', 'Lucida Sans', 'Arial'', 'sans-serif' ;*/
    /*margin-top: 10%;*/

}

.sidebarList .row.active {
    background-color: #afc5f8 !important;
}

.sidebarList .row:hover {
    /*cursor: pointer;*/
    /*background-color: dimgrey;*/

}

.sidebarList .row #active {
    background-color: dimgrey;
}

.sidebarList .row:nth-child(even) {
    background-color: #e5ebf4;
    /*background-color: f2f7ff;*/
}

.shown-bulk-buttons-holder {
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 30px;
    max-height: 100%;
    transition: 0.5s;
}

.hidden-bulk-buttons-holder {
    max-height: 0;
    transition: 0.5s;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    top: 30px;
    left: calc(-100% - 30px);
}

ul li div {
    /*margin-left: 4px;*/ /* Pantelis edited this */
    color: black;
}

/*.row #icon{*/
/*    flex: 30%;*/
/*    display: grid;*/
/*    place-items: center;*/

/*}*/
/*.filter{*/
/*    margin-top: -22%;*/
/*    margin-left: 5%;*/
/*}*/
.con-damages {

}

.row #title {
    flex: 70%;

}


.body {
    margin: 0;
    padding: 0;
}

.Bills {
    min-width: 1920px;
    /*min-height: 1080px;*/
    /*width: 100%;*/
    /*height: 100%;*/
    padding: 20px 20px 20px 30px;
    background-color: #e5ebf4;

    overflow-y: scroll;
}

.Documents {
    width: 100%;
    padding: 20px 20px 20px 30px;
    background-color: #e5ebf4;

    overflow-y: scroll;
}

.Bills2 {
    min-width: 100%;
    /*min-height: 1080px;*/
    /*width: 100%;*/
    /*height: 100%;*/
    padding: 20px;
    background-color: #e5ebf4;

    overflow-y: hidden;
}

.Bills2 .Bills {
    min-width: 800px;
    /*min-height: 0;*/

}

.Bills2 .Bills .Frame-Damages {
    min-width: 0;
    min-height: 300px;
}

.Frame-6 {
    min-width: 1870px;
    min-height: 340px;
    /*width: 100%;*/
    /*height: 100%;*/
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 80px;
    margin: 20px 10px 0 0;
    padding: 15px 30px;
    border-radius: 8px;
    background-color: #f4f9ff;
}

.Real-Grant-Logo {
    width: 200px;
    height: 50px;
    flex-grow: 0;
    object-fit: contain;
}

.Damage-Details {
    width: 139px;
}

.Headline-Filters1 {
    height: 32px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 0;
    margin-bottom: 0;
}

.Headline-Filters {
    height: 24px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 5px;
    padding: 0;
}

.Customer-Details {
    height: 298px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 14px;
    padding: 0;
    width: 453.3px;
}

.Separator-Stroke {
    height: 1px;
    align-self: stretch;
    flex-grow: 0;
    background-color: #d8d8d8;
    /*#d8d8d8*/
}

.Frame-5 {
    height: 18px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
    padding: 0;
}

.Status-Authorized {
    /*width: 102px;*/
    height: 18px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 0;
}

.Ellipse {
    width: 12px;
    height: 12px;
    flex-grow: 0;
    border-radius: 50%;
    align-self: center;
}

.Ellipse-1, .Ellipse-2 {
    background-color: red;
}

.status-1, .status-2 {
    color: red;
}

.Ellipse-3, .Ellipse-4 {
    background-color: #ff7700;
}

.status-3, .status-4 {
    color: #ff7700;
}

.Ellipse-5, .Ellipse-6 {
    background-color: #07aa35;
}

.status-5, .status-6 {
    color: #07aa35;
}


.Authorized {
    width: 80px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #07aa35;
}

.Status {
    width: 47px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
    margin-bottom: 0.5rem;
}

.Date-Demage-Reported {
    width: 170px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Date-Damage {
    width: 87px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Vehicle-Details {
    height: 298px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 14px;
    padding: 0;
    width: 453.3px;
}

.Headline-Filters {
    height: 24px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 5px;
    padding: 0;
    margin-bottom: 0;
}

.Dealer-Details {
    height: 21px;
    align-self: stretch;
}

.Expand-Icon {
    right: 38px;
    position: absolute;
}

.Date-Damage-Happened {
    width: 178px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.-km {
    /*width: 87px;*/
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
    /*margin-right: 0px;*/
}

.Vehicle-Mileage {
    width: 117px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Include-Tax {
    width: 84px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Tax {
    /*width: 30px;*/
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Contract-Number {
    width: 126px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.C-Number {
    /*width: 64px;*/
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;

}

.Damage-ID {
    width: 81px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.D-ID {
    /*width: 55px;*/
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Dealer-Details {
    height: 32px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    padding: 0;
}

.Dealer-Details-Intro {
    height: 21px;
    align-self: stretch;
}

.Dealer-Number {
    width: 96px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Del-Number {
    /*width: 119px;*/
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
}

.Name {
    width: 40px;
    height: 16px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #36363a;
}

.AutoHouse {
    /*width: 81px;*/
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Phone {
    width: 46px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Phone-Number {
    /*width: 101px;*/
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Mobile {
    width: 53px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Mobile-Number {
    /*width: 101px;*/
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Fax {
    width: 26px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Fax-Number {
    /*width: 101px;*/
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Email {
    width: 41px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.officeautohousecom {
    /*width: 168px;*/
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.CountryZIPCity {
    width: 124px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Austria2500Baden {
    /*width: 149px;*/
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Repair-Shop-Details {
    width: 173px;
}

.Damage-Details, .Repair-Shop-Details {
    height: 21px;
}

.Damage-Details, .Repair-Shop-Details, .Dealer-Details-Intro, .Damage-Bills, .Dealer-Details {
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: left;
    color: #36363a;
}

.Frame-Damages {
    /*min-width: 1870px;*/
    /*min-height: 680px;*/
    width: 100%;
    /*height: 100%;*/
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 8px;
    padding: 15px 20px;
    border-radius: 8px;
    background-color: #ffffff;
    margin: 0 5px 20px 0;
    /*overflow-y: hidden;*/
    overflow-y: scroll; /* Pantelis wrote this */
    height: 100%;
    position: relative;
}

.Frame-10 {
    min-height: 34px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0;
    padding: 0;
}

.Damage-Bills {
    min-width: 116px;
    min-height: 21px;
}

.Table-Labels {
    height: 32px;
    align-self: stretch;
    flex-grow: 0;
    /*display: flex;*/
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0;
    padding: 0 20px;
}

.mainButton {
    /*width: 130px;*/
    width: inherit;
    height: 34px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 8px 10px;
    border-radius: 6px;
}

.mainButton.btn-warning {
    background-color: #FF7700 !important;
    border-color: #FF7700 !important;
}

.mainButton.btn-warning:hover {
    background-color: #d96600 !important;
    border-color: #d96600 !important;
}

/*label {*/
/*    !*width: 59px;*!*/
/*    width: 100%;*/
/*    height: 18px;*/
/*    flex-grow: 0;*/
/*    font-family: Roboto, sans-serif;*/
/*    font-size: 15px;*/
/*    font-weight: normal;*/
/*    font-stretch: normal;*/
/*    font-style: normal;*/
/*    line-height: normal;*/
/*    letter-spacing: 0.75px;*/
/*    text-align: center;*/
/*    color: #ffffff;*/
/*}*/
.innerBtnPicture {
    width: 15px;
    height: 15px;
    object-fit: contain;
    position: relative;
    top: -3px;
    left: -2px;
}

.Icon-Add-White {
    width: 13px;
    height: 13px;
    flex-grow: 0;
    object-fit: contain;

}

.Bill-ID {
    /*width: 160px;*/
    width: 8.79%;
    height: 32px;
}

.Date-Created {
    /*width: 180px;*/
    width: 9.89%;
    height: 16px;
}

.Input-label, .hash, .Shop, .Bill-ID, .Total, .Date-Created {
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    color: #a4a9ad;
}

.Shop {
    /*width: 600px;*/
    width: 32.97%;
    height: 16px;
}

.Total {
    /*width: 200px;*/
    width: 9.98%;
    height: 16px;

}

.Status-Table {
    width: 30.56%;
    height: 16px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
}

.Frame-2 {
    /*width: 102px;*/
    width: 6.6%;
    /*height: 20px;*/
    height: 18px;
    flex-grow: 0;
    /*display: flex;*/
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    padding: 0;
}

.preview {
    /*width: 20px;*/
    /*height: 16px;*/
    width: 18px;
    height: 14px;
    margin: 2px 25px 2px 0;
    /*background-color: #a4a9ad;*/
}

.Icon-View-Bills {
    /*width: 16px;*/
    /*height: 20px;*/
    width: 14px;
    height: 18px;
    margin: 0 25px 0 25px;
    object-fit: contain;
    /*background-color: #a4a9ad;*/
}

.Icon-Edit {
    /*width: 20px;*/
    /*height: 20px;*/
    width: 18px;
    height: 18px;
    flex-grow: 0;
    object-fit: contain;
}

.Bill-ID-Cell {
    width: 160px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Date-Created-Cell {
    width: 180px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Shop-Cell {
    width: 600px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.-EUR {
    width: 200px;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Status-Authorized-Table {
    width: auto;
    /*height: 18px;*/
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 0;
}

.Frame-3 {
    height: 80px;
    align-self: stretch;
    flex-grow: 0;
    /*display: flex;*/
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 0;
    padding: 0;
}

.Rectangle {
    width: 50px;
    height: 26px;
    padding: 3px 3px 3px 27px;
    background-color: #07aa35;
    border-radius: 40%;
}

.Field-Input-Switch {
    width: 108px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    padding: 0;
}

.Oval {
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 61%;
    /*background: radial-gradient(ellipse 65% 40%, transparent 0, transparent 90%, black 90%);*/

}

.Frame-9 {
    height: 26px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding: 0;
}

.link_checkbox[type="checkbox"] {
    position: absolute;
    margin-top: 4px \9;
    margin-left: -20px;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    /*position: absolute;*/
    /*top: 10px;*/
    /*left: 0;*/
    position: relative;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #CED4DA;
    border-radius: 3px;
}

.check-container:hover input ~ .checkmark, .select_all:hover input ~ .checkmark {
    background-color: #ccc;
}

.check-container input:checked ~ .checkmark, .select_all input:checked ~ .checkmark {
    background-color: #2f5bff !important;
    border: 2px solid #2f5bff !important;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.check-container input:checked ~ .checkmark:after, .select_all input:checked ~ .checkmark:after {
    display: block;
}

.check-container .checkmark:after, .select_all .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.check-container { /* Pantelis edited this */
    /*display: block;*/
    /*position: absolute;*/
    /*top: 0;*/
    /*width: 100px;*/
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    /*font-size: 22px;*/
    /*-webkit-user-select: none;*/
    /*-moz-user-select: none;*/
    /*-ms-user-select: none;*/
    /*user-select: none;*/
    margin: 0;
}

.select_all {
    color: #A4A9AD;
    display: flex;
    height: auto;
    padding: 0 10px 0 15px;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    font-family: Roboto, sans-serif;
}

.select_all > p {
    color: rgb(117, 132, 140);
    margin: 0;
    padding-left: 10px;
}

button .label {
    cursor: pointer;
}

.label {
    width: 100%;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: center;
    color: #ffffff
}

.Frame-18 {
    height: 34px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 0;
}

.hash {
    /*width: 20px;*/
    width: 2%;
    height: 16px;
}

.Handwork-price {
    width: 6.48%;
    height: 32px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
}

.Handwork-discount- {
    width: 6.48%;
    height: 32px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
}

.Handwork-covered- {
    width: 6.48%;
    height: 32px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
}

.Partner-discount- {
    width: 6.48%;
    height: 32px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
}

.Spare-part-price {
    width: 6.48%;
    height: 32px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
}

.Spare-part-price-cell {
    /*width: 6.48%;*/
    height: 18px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Spare-part-discount- {
    width: 6.48%;
    height: 32px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
}

.Spare-part-covered- {
    width: 6.48%;
    height: 32px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
}

.Material-discount- {
    width: 6.48%;
    height: 32px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
}

.Deduction-by-Contract- {
    width: 6.48%;
    height: 32px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
}

.Bad-stats-deduction- {
    width: 6.48%;
    height: 32px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
}

.VAT- {
    width: 6.48%;
    height: 16px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
}


.Seccond-Hand-Part-VAT- {
    width: 6.48%;
    height: 32px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
}

.Total-Label-Table {
    min-width: 100px;
}

.Total {
    width: 200px;

    height: 16px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
}

.Total-Cell {

    height: 18px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

/*td, th{*/
/*   width: 7.5%;*/
/*}*/
.Table-Row {
    align-self: stretch;
    flex-grow: 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0;
    padding: 10px 10px 10px 20px;
}

.Total-Label-Table, .Spare-part {
    /*height: 16px;*/
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    color: #a4a9ad;
}

.Rectangle-2 {
    width: 32px;
    height: 32px;
    margin: 0 0 5px 10px;
    padding: 5px 9px 9px 10px;
    border-radius: 4px;
    background-color: #07aa35;
    border-color: #07aa35;
}

.zoomInOutBtn {
    width: 32px;
    height: 32px;
    margin: 0 0 5px 10px;
    padding: 5px 9px 9px 10px;
    border-radius: 4px;
    background-color: #FF7700;
    border-color: #FF7700;
}

.zoomInOutBtn:hover, .zoomInOutBtn:focus, .zoomInOutBtn:active {
    background-color: #FF7700 !important;
    border-color: #FF7700 !important;
    box-shadow: unset !important;
}

.zoomInOutBtn.zoomed .zoomIn {
    display: none;
}

.zoomInOutBtn.notZoomed .zoomInExit {
    display: none;
}

.Frame-17 {
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 20px;
    padding: 0;

}

.Frame-17-New {
    /*height: 53px;*/
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 0;
    padding-top: 22px;
}

.Frame-16 {
    height: 399.5px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 40px;
    padding: 0 0 40px;
}

.Table-Number {
    /*width: 20px;*/
    width: 1.5%;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Electric-motor-control {
    /*width: 200px;*/
    width: 11%;
    height: 18px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Table-Price {
    height: 18px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;

}

.Table-Handwork-Discount {
    height: 18px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Table-Covered {
    height: 18px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Table-Partner-Discount {
    height: 18px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Table-Part-Discount {
    height: 18px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Table-Part-Covered {
    height: 18px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Table-Material-Discount {
    height: 18px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Table-Deduction-Contract {
    height: 18px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Table-Stats-Deduction {
    height: 18px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Table-Vat {
    height: 18px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Table-Hand-Part-Vat {
    height: 18px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Table-Total {
    width: 200px;
    height: 18px;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
}

.Icon-Decline {
    width: 20px;
    height: 20px;
    margin: 0 0 0 0;
    object-fit: contain;
    background-color: #ff0034;
}

.Frame-21 {
    height: 47px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 8px;
    padding: 0 0 20px;
}

.Field-Input-Shop {
    /*width: 500px;*/
    height: 53px;
    /*flex-grow: 3;*/
    flex: 3 1 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 5px;
    padding: 0;
}

.Field-Input-Text {
    height: 32px;
    align-self: stretch;
    flex-grow: 0;
    /*display: flex;*/
    display: contents;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 0;
    padding: 8px 10px 8px 15px;
    border-radius: 4px;
    background-color: #f2f4f4;
}

.Field-Input-Text div input {
    align-self: stretch;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    color: #36363a;
    background-color: unset;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 32px;
    padding-left: 7px;

}

.Field-Input-Text > div {
    display: flex !important;
    padding-top: 2px;
}

.info-text {
    align-self: stretch;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: 0.75px;
    text-align: left;
    color: #36363a;
    background-color: #f2f4f4;
    border: unset;
    padding: inherit;
    height: 25px;
}

.Input-text {
    align-self: stretch;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    color: #36363a;
    background-color: unset;
    border: 1px solid #ced4da;
    height: 32px;
}

.labelHolder .Input-label {
    align-self: center;
}

.labelHolder.flexTextRight .Input-label {
    text-align: right;
}

.labelHolder {
    display: flex;
    padding: unset;
}

.flexTextRight {
    justify-content: right;
}

.Field-Input-Drop-Add {
    height: 53px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 5px;
    padding: 0;
    width: 100%;
}

.Field-Filters {
    align-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    /*padding: 8px 15px;*/
    border-radius: 4px;
    /*border: solid 1px #a4a9ad;*/
}

.No-filters {
    width: 100%;
    height: calc(1.2em + .75rem + 2px);
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #36363a;
    /*margin-bottom: 0rem;*/
}

.No-filters-search {
    width: 100%;
    height: 40px;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #36363a;
    margin-bottom: unset;
    margin-right: 15px;
}

.No-filters-search select {
    height: 40px;
    border: unset;
}

.Path {
    width: 9.7px;
    height: 6.5px;
    flex-grow: 0;
    color: #a4a9ad;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 10px solid;

}

.Rectangle-Blue {
    width: 32px;
    height: 32px;
    /*margin: 0 0 0 10px;*/
    margin: -4px 0 0 0;
    padding: 5px 9px 9px 10px;
    border-radius: 4px;
    background-color: #2f5bff;
}

.Spare-part textarea:focus, .Paying-code textarea:focus {
    color: #495057;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    border-radius: 0.25rem;
}

.Spare-part textarea, .Paying-code textarea, .Code textarea {
    padding: 0.325rem 0.75rem;
    resize: none;
    border: none;
    background: inherit;
    height: auto;
}

.Paying-code, .Spare-part {
    overflow: visible;
}

.Input-label {
    /*height: 16px;*/
    align-self: stretch;
    width: auto;
    display: block;
    margin-bottom: 2px;
}

.status_wrapper, .currency_wrapper {
    display: flex;
    justify-content: center;
    gap: 1em;
    align-items: center;
}

.currency_wrapper {
    gap: 5px;
    position: relative;
}

.currency_wrapper > p {
    margin: 0;
}

.currency_message {
    position: absolute;
    background: #f4f9ff;
    border: 1px solid rgb(117, 132, 140);
    border-radius: 10px;
    top: 43px;
    width: 280px;
    padding: 5px;
    font-size: 14px;
    z-index: 2;
    display: block;
    animation: fadeIn ease 0.5s;
}

.currency_message_hide {
    display: none;
}

@keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

.info-label {
    /*height: 16px;*/
    align-self: stretch;
    flex-grow: 0;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.7px;
    text-align: left;
    color: #a4a9ad;
    width: auto;
    display: block;
}

.Frame-14 {
    /*height: 53px;*/
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    padding: 0;
    /*padding-top: 28px;*/
}

.Field-Input-Drop {
    position: relative;
    height: 53px;
    /*flex-grow: 1;*/
    /*flex: 1 2 150px;*/
    flex: 1 2 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 5px;
    padding: 0;
}

.Frame-19 {
    height: 32px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    padding: 0;
}

.Frame-20 {
    height: 146.5px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 8px;
    padding: 0;
}

.Icon-Decline {
    width: 20px;
    height: 20px;
    margin: 0 0 0 0;
    object-fit: contain;
    background-color: #ff0034;
}

tr.someClass:nth-child(even) td {
    background-color: #f2f7ff;
}

th {
    background-color: #ffffff;
}

.Table-Row-Total {
    border-top: 1px solid #d8d8d8;
    align-self: stretch;
    flex-grow: 0;
    width: 100%;
    height: 47px;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    padding: 0 0 20px;
    /*background-color: #1ea7fd;*/
    font-weight: 500;
}

.Table-Cell-Button {
    width: 7.5%;
}


.table_class tbody > tr > td {
    font-size: 14px;
    color: #36363a;
    text-align: left;
    font-family: Roboto, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    /*overflow: hidden;*/
}

.billItemsTbody > table {
    width: 100%;
}

.table_class tbody tr .Table-Row-Total {
    font-weight: 500;
}

.Table-Row-Total td {
    font-weight: 500;
}

.Frame-3 tr td:nth-child(5) {
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
}

.Frame-3 tr td {
    text-align: left;
    font-size: 15px;
    letter-spacing: 0.75px;
    color: #36363a;
    vertical-align: middle;

}

.hash tbody > tr > td {
    letter-spacing: 0.75px;
    color: #36363a;
    font-size: 15px;
}

.Handwork-price tbody > tr > td {
    letter-spacing: 0.75px;
    color: #36363a;
    font-size: 15px;
}

.Handwork-discount- tbody > tr > td {
    letter-spacing: 0.75px;
    color: #36363a;
    font-size: 15px;
}

.Handwork-covered- tbody > tr > td {
    letter-spacing: 0.75px;
    color: #36363a;
    font-size: 15px;
}

.Partner-discount- tbody > tr > td {
    letter-spacing: 0.75px;
    color: #36363a;
    font-size: 15px;
}

.Spare-part-price tbody > tr > td {
    letter-spacing: 0.75px;
    color: #36363a;
    font-size: 15px;
}

.Spare-part-discount- tbody > tr > td {
    letter-spacing: 0.75px;
    color: #36363a;
    font-size: 15px;
}

.Spare-part {
    min-width: 100px;
}

.Spare-part-covered- tbody > tr > td {
    letter-spacing: 0.75px;
    color: #36363a;
    font-size: 15px;
}

.Material-discount- tbody > tr > td {
    letter-spacing: 0.75px;
    color: #36363a;
    font-size: 15px;
}

.Deduction-by-Contract- tbody > tr > td {
    letter-spacing: 0.75px;
    color: #36363a;
    font-size: 15px;
}

.Bad-stats-deduction- tbody > tr > td {
    letter-spacing: 0.75px;
    color: #36363a;
    font-size: 15px;
}

.VAT- tbody > tr > td {
    letter-spacing: 0.75px;
    color: #36363a;
    font-size: 15px;
}

.Seccond-Hand-Part-VAT- tbody > tr > td {
    letter-spacing: 0.75px;
    color: #36363a;
    font-size: 15px;
}

.Total-Label-Table tbody > tr > td {
    letter-spacing: 0.75px;
    color: #36363a;
    font-size: 15px;
}

.Last-Table-Cell {
    width: 60px;
    height: 38px;
}

.buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    height: 100%;
}

.Table-Row-Total tbody > tr > td {
    font-weight: 500;
}

.Bill-ID tbody > tr > td {
    color: #36363a;
    letter-spacing: 0.75px;
    font-size: 15px;
}

.Date-Created tbody > tr > td {
    color: #36363a;
    letter-spacing: 0.75px;
    font-size: 15px;
}

.Shop tbody > tr > td {
    color: #36363a;
    letter-spacing: 0.75px;
    font-size: 15px;
}

.Total tbody > tr > td {
    color: #36363a;
    letter-spacing: 0.75px;
    font-size: 15px;
}

.Status-Table tbody > tr > td {
    color: #36363a;
    letter-spacing: 0.75px;
    font-size: 15px;
}

.Frame-2 tbody > tr > td {
    color: #36363a;
    letter-spacing: 0.75px;
    font-size: 15px;
}

.Frame-3 tr td {
    padding: 7px;

}

.head tr th {
    padding: 7px;
}

.table_bills {
    position: unset;
}

.table thead th {
    border-bottom: none;
}

.Frame-Table-Bills {
    overflow: scroll;
}

.Table-Row .form-control {
    background-color: transparent;
}

.Table-Row .Paying-code .autocompleteDiv.autocompleteDivWarning {
    outline: 2px solid red;
    border-radius: 3px;
}

.Table-Row .autocompleteDiv {
    display: flex;
}

.Table-Row .autocompleteDiv > div {
    height: 100%;
    width: 100%;
}

.Table-Row .autocompleteDiv > div input {
    height: 100%;
    border: unset;
    background: transparent;
    min-height: 35px;
}

.Table-Row .Paying-code .autocompleteDiv > div input {
    height: 100%;
    border: unset;
    background: transparent;
    width: 100%;
}

.table_class tbody > tr > td {
    position: relative;
    border-left: none;
    border-right: 1px solid #ddd;
}

.table_class tbody > tr > td:first-child {
    border-left: 1px solid #ddd;
    text-align: center;
    padding: 10px 1px;
}

.table_class tbody td {
    border-top: 1px solid #ddd;
}

.table_class tbody td {
    border-bottom: 1px solid #ddd;
}
.table_class tbody td:last-child {
    border-bottom: none;
}
.table_class tbody > tr > td.align-text-right,
.align-text-right {
    text-align: right;
}

.align-text-center {
    text-align: center;
}

.table_class .form-control {
    border: 0;
    font-size: 14px;
}

.Type {
    width: 97px;
}

td.Type select {
    height: 35px;
    width: auto;
    background: transparent;
    border: unset;
}

/*td.Type select[aria-readonly] {*/
/*    pointer-events: none;*/
/*}*/
/* Chrome, Safari, Edge, Opera */
.table_class input::-webkit-outer-spin-button,
.table_class input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.table_class input[type=number] {
    -moz-appearance: textfield;
}

.form-control {
    padding: 5px 8px;
    font-size: 14px;
}

.DamageInfo {
    /*min-width: 1500px;*/
    min-height: 70px;
    /* width: 100%; */
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    grid-gap: 8px;
    gap: 8px;
    /*margin: 20px 5px 10px 0px;*/
    /*padding: 15px 20px;*/
    /*border-radius: 8px;*/
    background-color: #ffffff;
    border-bottom: 1px solid #f3f3f3;
}

.DamageInfo .row {
    margin-bottom: 3px;
}

.InvGenerals {
    /*min-width: 1500px;*/
    min-height: 100px;
    /* width: 100%; */
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    /*margin: 20px 5px 10px 10px;*/
    padding: 15px 0;
    border-radius: 8px;
    background-color: #ffffff;
}

.DamageInput {
    width: 100%;
}

.DamageInfo .form-control {
    width: 100%;
    margin-bottom: 5px;
}

.Code, .Type, .Quantity, .Price, .Discount, .Total-for-payment, .Paying-code {
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: left;
    color: #a4a9ad;
}
.Price, .Quantity, .Paying-code, .Code, .Discount {
    min-width: 60px;
}

.Total-for-payment {
    width: auto;
}

.List {
    min-width: 20%;
    max-width: 30%;
}

.BillForm {
    min-width: calc(100% - 520px);
    max-width: calc(100% - 320px);
    height: calc(100vh - 28px);
    width: 80%;
}

.billButtonsHolder {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 1em;
    position: sticky;
    bottom: 0;
}

.searchBar {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 3px;
    height: 38px;
    font-size: 14px;

}

.FrameBillIcon {
    justify-content: space-between;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0;
    gap: 0;
    padding: 0;
}

.tableThead {
    z-index: 1;
    top: 0;
    position: sticky;
    background: white;
}

.tableThead > div:not(:first-child) {
    text-align: center;
}

.tableThead > div {
    display: inline-block;
}

.billItemsTbodyWrap {
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 8px;
    /*margin: 20px 5px 10px 10px;*/
    /*padding: 15px 20px;*/
    /*border-radius: 8px;*/
    border-bottom: 1px solid #ddd;
    background-color: rgb(255, 255, 255);
    overflow-y: scroll;
    max-height: calc(100vh - 667px);
    transition: max-height ease 1s;
}

.billItemsTbody {
    background-color: rgb(255, 255, 255);
    width: 100%;
    max-height: calc(100vh - 667px);
    transition: max-height ease 1s;
    overflow-y: auto;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    /*min-width: 1118px;*/
}

.zoomedItems .billItemsTbody {
    max-height: calc(100vh - 275px);
    transition: max-height ease 1s;
}

.totals {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 0;
    width: 100%;
}

.totals > table:nth-of-type(1) {
    flex: 0 0 auto;
}

.totals > table:nth-of-type(2) {
    flex: 0 0 auto;
}

.totalsTable {
    /*float: right;*/
    /*width: 500px;*/
    margin-top: 10px;
}

.totalsTable > thead > tr > th {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: right;
    color: #a4a9ad;
    padding-left: 50px;
}

.totalsTable > tbody > tr > td:first-child {
    text-align: right;
    padding-left: 50px;
}

.totalsTable > tbody > tr > td {
    text-align: right;
    padding-left: 50px;
}
.tr > tbody > tr > td:last-child{
    /*padding-right: 0px;*/
}
.text-last{
    text-align-last: right;
}

.modal-dialog {
    max-width: 700px;
}

.SelectDocument {
    margin-left: 15px;
    margin-top: 10px;
}

/**
    Styling of user roles and permissions by Vanja
 */
.header {
    background-color: #f4f9ff;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 1rem;
    text-align: left;
}

.users-container, .roles-container, .permissions-container {
    width: 100%;
    padding: 20px 20px 20px 30px;
    height: 100vh;
    box-sizing: border-box;
}

.list-container {
    text-align: left;
    background-color: #ffffff;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 8px;
    /*height: 100%;*/
}

.list-container table {
    width: 100%;
}

.toolbox {
    margin-bottom: 2rem;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
}

.modal-narrow {
    width: 400px;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 2rem 3rem 2rem 3rem;
    border-radius: 8px;
}

.modal-normal {
    width: 600px;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 2rem 3rem 2rem 3rem;
    border-radius: 8px;
}

.modal-narrow button {
    margin-right: 1rem;
}

.modal-wide {
    width: 80%;
    height: auto;
    max-height: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 2rem 3rem 2rem 3rem;
    border-radius: 8px;
    overflow: auto;
}

.modal-wide_no_overflow {
    width: 80%;
    height: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 2rem 3rem 2rem 3rem;
    border-radius: 8px;
}

.modal-narrow button {
    margin-right: 1rem;
}
.select_field .css-12jo7m5{
    white-space: break-spaces!important;
    text-align: left!important;
}
 .damage-info-item  .km_capitalize{
    text-transform: unset!important;
}

.advanced_search_wrapper {
    position: absolute;
    background-color: #f4f9ff;
    border-radius: 8px;
    width: 500px;
    left: 385px;
    z-index: 4;
    padding: 20px;
    border: 1px solid #ced4da;
    overflow: auto;
    max-height: calc(100vh - 28px);
    flex-direction: column;;
}

.advanced_search_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.advanced_search_row {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;
}


@media screen and (max-width: 900px) {
    .advanced_search_row {
        flex-direction: column;
    }

    .advanced_search_wrapper {
        width: auto;
    }
}

.advanced_search_input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.advanced_search_button_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.advanced_search_button {
    border: none;
    background: transparent;
    padding: 0;
    display: flex;
}

.advanced_search_button > svg {
    width: 20px;
    height: 20px;
}

#advancedSearch {
    padding-inline: 10px;
    gap: 30px;
}
.includeTaxHolder{
    font-size: 13px;
}
.MuiFormControlLabel-label {
    font-size: 13px;
}
.custom-select{
    background: red;
}
.plus-button{
    width: 18px;
    height: 18px;
    background-color: transparent;
    border: none;
}
.table_class tbody > tr:last-child{
    border-left: none!important;
    border-right: none!important;
    border-top: none!important;
    border-bottom: none!important;
}
.table_class tbody > tr > td:last-of-type{
    border-left: none!important;
    border-right: none!important;
    border-top: none!important;
    border-bottom: none!important;
    background-color: white!important;
}
.table_class tbody > tr > td{
    white-space: break-spaces;
    vertical-align: top;
}

.Field-Input-Drop input:not([type='range']){
    text-align: right;
}
.text-align-text-damage{
    text-align: left!important;
}
.Code textarea{
    /*max-width: 100px;*/
    /*min-width: 100px;*/
}
.damage-status-buttons-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem

}
.damage-buttons-half{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.label-rechnungen{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 13px 12px;
    font-size: 18px;
}
.damage-info-hole-container{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: .5em 0;
    gap: 1rem;
}
.damage-info-status-and-edit{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
}
.plus-icon{
    background: transparent!important;
    border: none;
    padding: 0 0 0 5px;
}


@media (max-width: 1200px) {
    .damage-status-buttons-container{
        flex-direction: column;
        align-items: flex-end;
    }
}

.input-wrap, .input-wrap_1, .input-wrap_2 {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.input-wrap_2, .input-wrap_2 > .autocompleteDiv {
    width: 100%;
}

.input, .input_1, .input_2 {
    width: 100%;
    left: 0;
}

.width-machine, .width-machine_1, .width-machine_2 {
    visibility: hidden;
    padding-inline: 8px;
    height: 1px;
    white-space: nowrap;
    position: absolute;
}

.width-machine_1 {
    white-space: break-spaces;
    position: absolute;
    height: auto;
    min-width: 100px;
}
.position-context-menu-item{
    display: flex;
    flex-direction: row;
    padding-inline: 10px;
}
.position-context-menu-item:hover{
    background: #AFCEFF;
}
.selected-cells-bauteil{
    border: 2px solid #629EFF!important;
    border-collapse: collapse!important;
}
.context-menu{
    position: absolute;
    z-index: 500;
    background: white;
    border-radius: unset;
    padding-block: 10px;
    font-size: 14px;
    font-family: Roboto, sans-serif;
}
.spare-part-position{
    max-width: 60px;
}
.hash-background{
    background-color: #AFCEFF!important;
}
.Spare-part-value .spare_part_label:focus{
    outline: none!important;
    box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
}
.spare_part_label .form-control:hover{
    color: white!important;
}
.Spare-part-value label{
    margin-bottom: 0;
}
.searchPermissions{
    border-radius: 10px;
    margin-bottom: 10px;
}
.searchPermissions:focus-visible{
    outline: none;
    border: 2px solid #007bff!important;
}
.currency_wrapper {
    display: flex;
    justify-content: center;
}
.validation_true{
    border: 1px solid green!important;
}
.validation_false{
    border: 1px solid red!important;
}

.info_popup {
    background: #fff0c5;
    text-align: center;
    position: absolute;
    top: 20px;
    padding: 20px 10px;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.info_popup > p {
    margin: 0;
}

.pop_up_title {
    text-transform: uppercase;
    font-weight: bold;
}
.admin-and-logout{
    display: flex;
    flex-direction: row;
}
.admin-tab-container{
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.admin-tab{
    background: white;
    padding: 15px 20px;
    border-radius: 18px;
    cursor: pointer;
    color: #007bff;
    border: 1px solid #007bff;
}
.admin-tab:hover{
    color: white;
    background: #007bff;
}
.admin-tab:focus{
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5);
}